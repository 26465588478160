import request from '_utils/request'

const get_code = (params) => {
  return request({
    url: '/api/login/getCode',
    method: 'GET',
    params
  })
}

//登录
const do_login = (data) => {
  return request({
    url: "/api/login/signup-login",
    method: "POST",
    data,
  });
};

// 获取手机号
const get_user_number = (data) => {
  return request({
    url: "/hx/number",
    method: "POST",
    data,
  });
};

// 获取手机号
const get_xafq_user_number = (data) => {
  return request({
    url: "/hx/xafq/number",
    method: "POST",
    data,
  });
};

// 获取登录用户信息
const get_login_user = (params) => {
  return request({
    url: '/api/user/getLoginUser',
    method: 'GET',
    params
  })
}

//客服电话
const get_config_by_key = (key) => {
  return request({
    url: `/api/system/config/getValue/${key}`,
    method: 'GET'
  })
}

export {
  get_code,
  do_login,
  get_user_number,
  get_xafq_user_number,
  get_login_user,
  get_config_by_key
}